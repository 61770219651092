<template>
  <div class="content">
    <div id="forgotpassword">
      <div class="container">
        <!-- <img src="../assets/image/logo_header.png" alt class="logo-header" /> -->
        <h2>ตั้งรหัสผ่านใหม่</h2>
        <el-form
          :label-position="'top'"
          :model="resetPssword"
          status-icon
          :rules="rules"
          ref="resetPssword"
          class="form-forgot"
        >
          <el-form-item label="รหัสผ่านใหม่" prop="pass">
            <el-input type="password" v-model="resetPssword.pass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="ยืนยันรหัสผ่านใหม่" prop="checkPass">
            <el-input type="password" v-model="resetPssword.checkPass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <e-button
              class="btn btn-color-dark w-100"
              @click="submitForm('resetPssword')"
            >ขอรหัสผ่านใหม่</e-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<style  scoped>
.btn-color-dark {
  padding: 5px 0 !important;
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 30px;
}
</style>
<script>
import { HTTP } from "@/service/axios.js";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่าน"));
      } else if (value.length < 8) {
        callback(new Error("รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร"));
      } else {
        if (this.resetPssword.checkPass !== "") {
          this.$refs.resetPssword.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่าน"));
      } else if (value !== this.resetPssword.pass) {
        callback(new Error("รหัสผ่านไม่ตรงกัน"));
      } else {
        callback();
      }
    };
    return {
      resetPssword: {
        pass: "",
        checkPass: "",
      },
      rules: {
        pass: [
          {
            required: true,
            validator: validatePass,
            trigger: "blur",
          },
        ],
        checkPass: [
          {
            required: true,
            validator: validatePass2,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openTabLogin() {
      this.$store.commit("SET_MODAL", true);
      this.$store.commit("TAB_LOGIN", true);
      this.$store.commit("TAB_REGIS", false);
      this.$store.commit("TAB_FORGET", false);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            code: this.$route.params.code,
            password: this.resetPssword.checkPass,
          };
          HTTP.post(`reset/password`, obj)
            .then((res) => {
              console.log(res);
              if (res.data.success) {
                this.$message({
                  message: "เปลี่ยนรหัสผ่านสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$router.push(`/home`);
                setTimeout(() => {
                  this.openTabLogin();
                }, 800);
              } else {
                this.alertFailError();
              }
            })
            .catch((e) => {
              this.alertCatchError(e);
              console.log("เปลี่ยนรหัสผ่านไม่สำเร็จ");
            });
        }
      });
    },
  },
};
</script>
